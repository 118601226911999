import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { UserApi } from "./user-api";
import { MaterialApi } from "./material-api";
import { lastValueFrom } from "rxjs";
import { ScanBulk } from "./scan-bulk-api";
import { environment } from "src/environments/environment";
import { ODataStore } from "./odata-store";
import { AgencyApi } from "./agency-api";
import { StockApi } from "./stock-api";

@Injectable({ providedIn: 'root' })
export class FlashmagService {
    private static baseApiUrl = environment.apiUrl;
    private httpClient = inject(HttpClient);

    public agencyStore = new ODataStore<AgencyApi>(this.httpClient, 'ODataAgency');
    public userStore = new ODataStore<UserApi>(this.httpClient, 'ODataUser');
    public materialStore = new ODataStore<MaterialApi>(this.httpClient, 'ODataMaterial');
    public stockStore = new ODataStore<StockApi>(this.httpClient, 'ODataStock');

    async pushScanBulk(scanBulk: ScanBulk) {
        return await lastValueFrom(
            this.httpClient.post(`${FlashmagService.baseApiUrl}/api/Scan/bulk`, scanBulk, { headers: {} })
        );
    }

    async getVersion() {
        return await lastValueFrom(
            this.httpClient.get<string>(`${FlashmagService.baseApiUrl}/version`)
        );
    }
}

