import { HttpClient } from "@angular/common/http";
import { lastValueFrom } from "rxjs";
import { ODataResponse } from "./odata-response";
import { environment } from "src/environments/environment";


export class ODataStore<Api> {
    private static baseApiUrl = environment.apiUrl;
    private readonly httpClient;
    private readonly odataEntity;

    constructor(httpClient: HttpClient, odataEntity: string) {
        this.httpClient = httpClient;
        this.odataEntity = odataEntity;
    }

    async queryIn(member: string, ...values:unknown[]){
        const searchParams = new URLSearchParams();
        const searchClauses: string[] = [];
        if (values?.length)
            searchClauses.push(...values.map(v => `${member} eq ${v}`));
        let filter = searchClauses.join(' or ');        
        searchParams.append('$filter', filter);
        return (await lastValueFrom(
            this.httpClient.get<ODataResponse<Api[]>>(`${ODataStore.baseApiUrl}/odata/${this.odataEntity}?${searchParams.toString()}`, { headers: {} })
        )).value;
    }

    async query(options: {
        orderby?: string;
        search?: string;
        containInMembers?: string[];
        equalInMembers?: string[];
        agencyId?: number;
    }
    ): Promise<Api[]> {
        const searchParams = new URLSearchParams();
        if (!!options.orderby) searchParams.append('$orderby', options.orderby);

        const searchClauses: string[] = [];
        if (!!options.search && !!(options.containInMembers?.length))
            searchClauses.push(...options.containInMembers.map(m => `contains(${m},'${options.search}')`));

        if (!!options.search && !!(options.equalInMembers?.length))
            searchClauses.push(...options.equalInMembers.map(m => `${m} eq '${options.search}'`));

        let filter = searchClauses.join(' or ');
        if (options.agencyId !== undefined) {
            const agencyClause = `agencyId eq ${options.agencyId}`;
            filter = filter.length > 0 ?
                [`(${filter})`, agencyClause].join(' and ') :
                agencyClause;
        }
        if (filter.length)
            searchParams.append('$filter', filter);
        return (await lastValueFrom(
            this.httpClient.get<ODataResponse<Api[]>>(`${ODataStore.baseApiUrl}/odata/${this.odataEntity}?${searchParams.toString()}`, { headers: {} })
        )).value;
    }
}
