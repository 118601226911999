import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


import { TokenService } from './token.service';
import { UserApi } from './api/user-api';
import { FlashmagService } from './api/flashmag.service';
import { AgencyApi } from './api/agency-api';
import { MicrosoftGraphService } from './microsoft-graph.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly isAuthenticated$$ = new BehaviorSubject<boolean>(false);
  readonly isAuthenticated$ = this.isAuthenticated$$.asObservable();
  private readonly isUnauthorized$$ = new BehaviorSubject<boolean>(false);
  readonly isUnauthorized$ = this.isUnauthorized$$.asObservable();
  private readonly shouldChooseAgency$$ = new BehaviorSubject<boolean>(false);
  readonly shouldChooseAgency$ = this.shouldChooseAgency$$.asObservable();

  private _users: UserApi[] = [];
  private _selectedUser?: UserApi;
  private _selectedAgency?: AgencyApi;
  private _agencies: AgencyApi[] = [];

  private readonly tokenService = inject(TokenService);
  private readonly flashmagService = inject(FlashmagService);
  //private readonly microsoftGraphService = inject(MicrosoftGraphService);

  constructor() {
    this.tokenService.haveIdentity$.subscribe(async (identity) => {
      if (identity !== undefined) {
        this._users = (await this.flashmagService.userStore.query({
          search: this.tokenService.getIdentity(),
          equalInMembers: ['azureIdentifier']
        }));
        if (this._users !== undefined && this._users.length > 0) {
          this._agencies = await this.flashmagService.agencyStore.queryIn('id', ...this._users.map(u => u.agencyId));
          const subAgencies = await this.flashmagService.agencyStore.queryIn('parentAgencyId', ...this._agencies.map(a => a.id));
          if (subAgencies.length > 1)
            this._agencies.push(...subAgencies);

          if (this._agencies.length === 1)
            this.selectAgency(this._agencies[0]);
          else {
            this.shouldChooseAgency$$.next(true);            
          }

          //console.log(await this.microsoftGraphService.getAllEquansUser());
        } else {
          this.isUnauthorized$$.next(true);
        }
      }
    })
  }

  selectAgency(selectedAgency: AgencyApi) {
    this._selectedAgency = selectedAgency;
    this._selectedUser =
      this._users.find(u => u.agencyId === selectedAgency.id) ??
      this._users.find(u => u.agencyId === selectedAgency.parentAgencyId);
    this.isAuthenticated$$.next(true);
  }

  get isAuthenticated() {
    return !!this._selectedUser;
  }

  get isUnauthorized() {
    return this._users.length === 0;
  }

  get haveToken() {
    return !!this.tokenService.getBearerToken();
  }

  get identity() {
    return this.tokenService.getIdentity();
  }

  get users(): UserApi[] {
    return this._users;
  }

  get selectedUser(): UserApi {
    if (!this._selectedUser)
      throw new Error('Cannot get user, not authenticated');
    return this._selectedUser;
  }
  get agencies(): AgencyApi[] {
    return this._agencies;
  }

  get selectedAgency(): AgencyApi {
    if (!this._selectedAgency)
      throw new Error('Cannot get agency, not authenticated');
    return this._selectedAgency;
  }

  get parentAgency(): AgencyApi {
    const selectedAgency = this.selectedAgency;
    if(!this.selectedAgency.parentAgencyId) 
      return selectedAgency;

    const parentAgency = this._agencies.find(a => a.id === this.selectedAgency.parentAgencyId);
    if (parentAgency === undefined)
      throw new Error('Cannot get parent agency');
    return parentAgency;
  }

  async logout(): Promise<void> {
    await this.tokenService.logout();
    this.isAuthenticated$$.next(false);
  }

  isOneOfRoles(neededRoles: string[]): boolean {
    if (neededRoles.length === 0)
      return true;

    return (
      this.isAuthenticated && neededRoles.some(r => r === this._selectedUser?.role)
    );
  }
}


